import React from "react";
import { Layout } from "../../components";

const Strategia = ({ location: { pathname } }) => {
  return (
    <Layout
      seo={{
        title: "Strategia",
        href: pathname,
        lang: "pl"
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/offer/strategy/",
      }}
    >
      <section className="single_offer">
        <div className="single_offer__container">
          <div className="single_offer__header text-center">
            <h1>Strategia</h1>
          </div>
          <div className="single_offer__content">
            <p class="text-center">Strategia marki - strategia komunikacji</p>
            <p class="text-center">
              Dobra strategia powinna być realna do wdrożenia i skuteczna. Prace
              związane z opracowaniem strategii obejmują warsztat z Klientem a
              następnie część audytorską, analityczną i strategiczną. W części
              audytorskiej badamy obecny stan marki, czym dysponuje, jak się
              komunikuje, jak realizuje cele. W części analitycznej
              opracowujemy: analizę rynku, analizę konkurencji, analizę grupy
              docelowej, przegląd marki i pozycjonowanie, analizę SWOT. W części
              strategicznej definiujemy; cele komunikacyjne i sprzedażowe, grupy
              docelowe marki, opracowujemy strategię komunikacji marki,
              strategię promocji a całość zamykamy w harmonogramie działań.
            </p>
            <p class="text-center">
              Efektem prac jest kilkudziesięcio lub kilkusetstronicowy dokument
              z analizą marki, rynku, strategią komunikacji, promocji i
              harmonogramem działań. Dokument strategiczny omawiamy z Klientem w
              formie podsumowującego spotkania warsztatowego.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default Strategia;
